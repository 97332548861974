import { RootState } from '../index';

export const selectProjectLoading = (state: RootState) => state.project.loading;
export const selectProjectError = (state: RootState) => state.project.error;
export const selectUserEmailsTableData = (state: RootState) => state.project.userEmailsTableData;
export const selectInitialProjectDetails = (state: RootState) => state.project.projectInitialDetails;
export const selectUsersListByRole = (state: RootState) => state.project.usersByRoleList;
export const selectAllProjectsList = (state: RootState) => state.project.allProjectsList;
export const selectUserEmailsForProject = (state: RootState) => state.project.userEmailsForProject;
export const selectSelectedProjectData = (state: RootState) => state.project.selectedProjectData;
export const selectProjectConfigData = (state: RootState) => state.project.selectedProjectConfigData;
export const selectGetAllAssetForProject = (state: RootState) => state.project.assetDataForProject;
export const selectSelectedAsset = (state: RootState) => state.project.selectedAsset;
export const selectIsEditModeAsset = (state: RootState) => state.project.isEditModeAsset;
export const selectNewAssetRequestsList = (state: RootState) => state.project.allNewReqDataAsset;
export const selectPendingAssetRequestsList = (state: RootState) => state.project.allPendingReqDataAsset;
export const selectAssetRequestsHistoryList = (state: RootState) => state.project.allRequestHistoryData;
export const selectScopeDataOverView = (state: RootState) => state.project.scopeDataOverview;
export const selectDatabasesValuesOptions = (state: RootState) => state.project.databasesValuesOptions;
export const selectTablesValuesOptions = (state: RootState) => state.project.tablesValuesOptions;
export const selectTechnologiesValuesOptions = (state: RootState) => state.project.technologiesValuesOptions;
export const selectOldDatabaseValuesOptions = (state: RootState) => state.project.oldDatabaseOptions;
export const selectOldTablesValuesOptions = (state: RootState) => state.project.oldTablesOptions;
export const selectOldTechnologiesValuesOptions = (state: RootState) => state.project.oldTechnologiesOptions;
export const selectInitialProjectConfigData = (state: RootState) => state.project.initialConfigData;
export const selectRequestDetailsForAssetData = (state: RootState) => state.project.reqDetailForAsset;
export const selectConnectivityData = (state: RootState) => state.project.connectivityResponse;

