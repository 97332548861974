import { Stepper, Steppers } from "@appkit4/react-components";
import ModalContentForReportDashboard from "./ModalContentForReportDashboard";
import DatabaseSelectionSection from "./DatabaseSelectionSection";
import TableSelectionSection from "./TableSelectionSection";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

const ModalContentForAIModelScript: React.FC<{ assetType: string, backClicked: Function, activeIndex: number, setActiveIndex: Function, submitAddAsset: Function }> = ({ assetType, backClicked, activeIndex, setActiveIndex, submitAddAsset }) => {
  const { getValues } = useFormContext();
  const [databasesValue, setDatabasesValue] = useState<Array<string | number>>([]);
  const [tablesValue, setTablesValue] = useState<Array<string | number>>([]);
  const onStepperChange = (i: number) => {
    setActiveIndex(i);
  }
  const submitAssetReqFromTable = () => {
    submitAddAsset(getValues(), databasesValue, tablesValue);
  }

  return (
    <Steppers space={210} className="flex justify-between" activeIndex={activeIndex} onActiveIndexChange={onStepperChange}>
      <Stepper status='normal' label="Details">
        <ModalContentForReportDashboard assetType={assetType} backClicked={backClicked} submitBtnValue={"Select database"} />
      </Stepper>
      <Stepper status='normal' label="Database">
        <DatabaseSelectionSection setActiveIndex={setActiveIndex} databasesValue={databasesValue} setDatabasesValue={setDatabasesValue} />
      </Stepper>
      <Stepper status='normal' label="Table">
        <TableSelectionSection setActiveIndex={setActiveIndex} submitBtnValue={"Submit request"} submitAssetReqFromTable={submitAssetReqFromTable} tablesValue={tablesValue} setTablesValue={setTablesValue} />
      </Stepper>
    </Steppers>
  );
}

export default ModalContentForAIModelScript;