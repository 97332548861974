import { useFormContext } from "react-hook-form";
import { ControlledDropdown, ControlledInput } from "../../../core/components";
import { assetTypeOptions, environmentOptions } from "../../../services/constants";
import { Button } from "@appkit4/react-components";

type AddAssetFormErrors = {
    assetName: string;
    assetType: string;
    assetDescription: string;
    environment: string;
};

const ModalContentForReportDashboard: React.FC<{ assetType: string, backClicked: Function, submitBtnValue: string }> = ({ assetType, backClicked, submitBtnValue }) => {
    const { formState: { errors } } = useFormContext<AddAssetFormErrors>();
    const { setValue } = useFormContext();
    setValue("assetType", assetType);
    return (
        <div>
            <div className="my-4">
                <ControlledInput name="assetName" required={true} type="text" label="Asset name" error={errors.assetName} />
                {errors.assetName && <p className="!text-red-500 mt-2">{errors.assetName.message}</p>}
            </div>
            <div className="my-4">
                <ControlledDropdown name="assetType" label="Asset type" data={assetTypeOptions} required={true} isDisabled={true}/>
            </div>
            <div className="my-4">
                <ControlledInput name="assetDescription" required={false} type="text" label="Asset description" error={errors.assetDescription} />
                {errors.assetDescription && <p className="!text-red-500 mt-2">{errors.assetDescription.message}</p>}
            </div>
            <div className="my-4">
                <ControlledDropdown name="environment" label="Environment" error={errors.environment} data={environmentOptions} required={true} />
                {errors.environment && <p className="!text-red-500 mt-2">{errors.environment.message}</p>}
            </div>
            <div className="flex justify-end gap-2">
                <Button kind='secondary' onClick={backClicked}>Back</Button>
                <Button kind='primary' icon={submitBtnValue === "Submit request" ? "icon-check-hook-outline" : "icon-arrow-right-outline"}  type="submit">{submitBtnValue}</Button>
            </div>
        </div>
    )
}

export default ModalContentForReportDashboard;