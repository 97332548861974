import React, { useState } from 'react';
import LineageModelDetails from "./LineageModelDetails";
import { Button } from '@appkit4/react-components';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { setInitialProjectConfigData } from '../../../store/project/projectSlice';
import { selectConnectivityData, selectInitialProjectConfigData } from '../../../store/project/projectSelectors';
import { useSelector } from 'react-redux';
import { testConnectivityRequest } from '../../../store/project/projectThunks';

const ProjectConfiguration: React.FC<{ setActiveIndex: Function }> = ({ setActiveIndex }) => {
    const initialProjectConfigData: any = useSelector(selectInitialProjectConfigData);
    const [gcsBucket, setGCSBucket] = useState(initialProjectConfigData?.gcsBucket || "");
    const [bigQueryDataSetID, setBigQueryDataSetID] = useState(initialProjectConfigData?.bigQueryDataSetID || "");
    const connectivityData = useSelector(selectConnectivityData);
    
    const dispatch = useDispatch<AppDispatch>();
    const divClassName = connectivityData?.message ? "flex justify-between my-6" : "flex justify-end my-6";
    const cancelProject = () => {
        setActiveIndex(0);
    }
    const testConnectivity = () => {
        dispatch(testConnectivityRequest({gcsBucketName: gcsBucket, lineageDataset: bigQueryDataSetID}));
        dispatch(setInitialProjectConfigData({gcsBucket: gcsBucket, bigQueryDataSetID: bigQueryDataSetID}));
    }
    const nextClicked = () => {
        setActiveIndex(2)
    }
    return (
        <div className='flex-1 bg-[#F3F3F3] mt-4'>
            <h1 className="font-sans font-bold font-medium text-2xl py-4">Project configuration</h1>
            <div className="p-4 bg-white">
                <LineageModelDetails gcsBucket={gcsBucket} bigQueryDataSetID={bigQueryDataSetID} setGCSBucket={setGCSBucket} setBigQueryDataSetID={setBigQueryDataSetID}/>
                <div className={divClassName}>
                    {connectivityData && connectivityData.message !== "" &&
                        <div className={connectivityData?.connectivity === true ? "text-[#21812D] mt-2" : "text-[#C52A1A] mt-2"}>
                            {connectivityData?.connectivity === true &&
                                <>
                                    <span className="Appkit4-icon icon-cloud-outline" aria-hidden="true"></span>
                                    <span>Successful configuration!</span>
                                </>
                            }
                            {connectivityData?.connectivity === false &&
                                <>
                                    <span>Something went wrong with the configuration, please contact</span>
                                    <a className='ap-link ml-1 text-[#C52A1A]' href='mailto:support@pwc.com' rel="noreferrer" target='_blank'>support@pwc.com</a>
                                </>
                            }
                        </div>
                    }
                    <Button kind='secondary' icon="icon-cloud-check-outline" disabled={gcsBucket === "" || bigQueryDataSetID === ""}onClick={testConnectivity}>Test connectivity</Button>
                </div>
                <div className="flex justify-end gap-2">
                    <Button kind='secondary' onClick={cancelProject}>Back</Button>
                    <Button kind='primary' icon="icon-check-hook-outline" disabled={connectivityData?.connectivity === false} onClick={nextClicked}>Next</Button>
                </div>
                
            </div>

        </div>
    )
}

export default ProjectConfiguration;