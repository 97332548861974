import { useSelector } from "react-redux";
import { selectInitialProjectConfigData, selectInitialProjectDetails } from "../../store/project/projectSelectors";
import UsersTable from "./projectDetails/UsersTable";
import { Button, Input, TextArea } from "@appkit4/react-components";
import { CreateProjectRequest } from "../../api";
import dayjs from "dayjs";
import { typeData } from "./mockData";

interface ProjectFormProps {
    onSubmit: (project: CreateProjectRequest) => void;
    loading: boolean;
    setActiveIndex: Function
  }
  
  const ReviewProjectData: React.FC<ProjectFormProps> = ({ onSubmit, loading, setActiveIndex }) => {
    const initialProjectDetails: any = useSelector(selectInitialProjectDetails);
    const initialProjectConfigData: any = useSelector(selectInitialProjectConfigData);
    const submitProjectData = () => {
        const payload: CreateProjectRequest = {
            name: initialProjectDetails.name,
            type: initialProjectDetails.type,
            description: initialProjectDetails.description,
            adminList: initialProjectDetails.adminEmails,
            managerList: initialProjectDetails.managerEmails,
            businessUserList: [],
            startDate: initialProjectDetails.startDate,
            endDate: initialProjectDetails.endDate,
            gcsBucket: initialProjectConfigData?.gcsBucket,
            bigQueryId: initialProjectConfigData?.bigQueryDataSetID
        }
        onSubmit(payload);
    }
    return (
        <div className='flex-1 bg-[#F3F3F3] mt-4'>
            <h1 className="font-sans font-bold font-medium text-2xl py-4">Review</h1>
            <div className="p-8 bg-white">
                    <div className="mb-8">
                        <div className="flex justify-between">
                            <h3 className="font-medium text-xl">Project details</h3>
                            <Button className="mt-2" kind='secondary' icon="icon-edit-outline" onClick={() => setActiveIndex(0)}>Edit</Button>
                        </div>
                        <div className="w-2/3">
                            <Input required={true} type="name" title="Name" readonly value={initialProjectDetails?.name} />
                        </div>
                        <div className="w-2/3 flex mb-4 mt-4 sm:flex-col lg:flex-row gap-2">
                            <div className="w-full">
                                <Input readonly value={typeData?.filter(type => type.value === initialProjectDetails?.type)?.[0]?.label} title="Type" />
                            </div>
                            <div className="w-full">
                                <Input readonly required={true} title="Start Date" value={dayjs(new Date(initialProjectDetails?.startDate)).format("MM/DD/YYYY")} />
                            </div>
                            <div className="w-full">
                                <Input readonly required={true} title="End Date" value={dayjs(new Date(initialProjectDetails?.endDate)).format("MM/DD/YYYY")} />
                            </div>
                        </div>
                        <div className="w-2/3">
                            <TextArea readonly name="description" required={true} title="Description" maxLength={420} value={initialProjectDetails?.description} />
                        </div>
                    </div>
                    <div className="my-8">
                        <div className="flex justify-between">
                            <h3 className="font-medium text-xl">Project configuration</h3>
                            <Button kind='secondary' icon="icon-edit-outline" onClick={() => setActiveIndex(1)}>Edit</Button>
                        </div>
                        <div className="my-2">
                            <div className="w-3/4">
                                <Input name="gcsBucket" title="GCS Bucket" value={initialProjectConfigData?.gcsBucket} readonly />
                            </div>
                            <div className="my-2 w-3/4">
                                <Input name="bigQueryDataSetID" title="BigQuery Dataset ID" value={initialProjectConfigData?.bigQueryDataSetID} readonly />
                            </div>
                        </div>
                    </div> 
                    <div className="mb-4">
                        <div className="flex justify-between">
                            <h3 className="font-medium text-xl py-4">Users</h3>
                            <Button className="mt-2" kind='secondary' icon="icon-edit-outline" onClick={() => setActiveIndex(0)}>Edit</Button>
                        </div>
                        <UsersTable />
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button kind='primary' loading={loading} icon="icon-check-hook-outline" onClick={submitProjectData}>Complete</Button>
                    </div>
                </div>
        </div>

    )
}

export default ReviewProjectData;