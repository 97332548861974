import { Button, Footer } from "@appkit4/react-components";
 
const LoggedInFooter = () => {
    const footerContent = `© 2024 PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity.
        Please see <a class='ap-link' href='https://www.pwc.com/structure' target='_blank'>www.pwc.com/structure</a>  for further details.`;
    const footerType = 'text';
    const url = 'https://yoda.pwc.de/files/2928503d-2036-4736-86cc-4189c302c777/b0d8aab2-41fc-4ef6-a7b2-edac9d6f0ea9/Yoda_ Datenschutzhinweise für PwC Mitarbeitende & Partner_0624 pdf'
    return(
        <div className="flex justify-between px-4">
            <Footer className="p-4" content={footerContent} type={footerType}></Footer>
            <Button kind="text" className="mt-4" onClick={() => window.open(url, '_blank')}>Privacy statement</Button>
        </div>
    )
}

export default LoggedInFooter;