import { useState } from "react";
import { assetTypeOptions, environmentOptions } from "../../../services/constants";
import { Button, Input, TextArea } from "@appkit4/react-components";
import { useSelector } from "react-redux";
import { selectSelectedAsset } from "../../../store/project/projectSelectors";
import { RequestDTO } from "../../../api";
import DenialConfirmationModal from "./DenialConfirmationModal";
import dayjs from "dayjs";

const EditDataAssetScreenComp:React.FC<{selectedAssetReqeust: RequestDTO, actionOnRequest: Function}> = ({selectedAssetReqeust, actionOnRequest}) => {
    const [isDenialModalShow, setIsDenialModalShow] = useState(false);
    const selectedAsset = useSelector(selectSelectedAsset);
    const denialSubheader = "Please review the request thoroughly. If the applicant can not edit the asset, please justify the reason. The user will be notified."
    const closeDenialModal = () => {
        setIsDenialModalShow(false);
    }
    const denyAssetReq = (data: any) => {
        setIsDenialModalShow(false);
        actionOnRequest({requestTechnicalId: selectedAssetReqeust?.requestTechnicalId, requestBusinessId: selectedAssetReqeust?.requestBusinessId, actionType: "deny", actionComment: data?.denialRequest})
    };
    const approveAssetReq = () => {
        actionOnRequest({requestTechnicalId: selectedAssetReqeust?.requestTechnicalId, requestBusinessId: selectedAssetReqeust?.requestBusinessId, actionType: "approved"})
    }
    return(
        <div className="bg-white p-4">
            <div className="mb-4">
            <h1 className="font-sans font-medium text-xl">Asset details edit</h1>
            <div>The following user has edited an asset. Please review the details and deny or approve the request.</div>
            </div>
            <div>
                <h1 className="font-sans font-medium text-xl">Asset details</h1>
                <div className="my-4">
                    <Input name="assetName" required={false} type="text" title="Asset name" readonly value={selectedAsset?.assetName} />
                </div>
                <div className="my-4">
                    <Input readonly required={false} value={assetTypeOptions.filter((type) => type.value === selectedAsset?.assetType)?.[0]?.label} title="Asset type" />
                </div>
                <div className="my-4">
                    <TextArea required={false} readonly value={selectedAsset?.assetDescription} title={"Asset description"}/>
                </div>
                <div className="my-4">
                    <Input readonly required={false} type="text" value={selectedAsset?.createdBy} title="Created by"/>
                </div>
                <div className="my-4">
                    <Input readonly required={false} type="text" value={dayjs(new Date(selectedAsset?.createdAt || '')).format("MM/DD/YYYY")} title="Creation date"/>
                </div>
                <div className="my-4">
                    <Input readonly required={false} type="text" value={selectedAsset?.updatedBy}  title="Updated by"/>
                </div>
                <div className="my-4">
                    <Input readonly required={false} type="text" label="Updated Date" value={dayjs(new Date(selectedAsset?.updatedAt || '')).format("MM/DD/YYYY")} title="Updated date"/>
                </div>
                <div className="my-4">
                    <Input readonly required={false} type="text" label="Environment" value={environmentOptions?.filter((environment) => environment.value === selectedAsset?.environment)?.[0]?.label} title="Environment" />
                </div>
            </div>
            <div className="flex justify-end gap-2">
                <Button kind='secondary' icon="icon-close-outline" onClick={() => setIsDenialModalShow(true)}>Deny</Button>
                <Button kind='primary' icon="icon-check-hook-outline" onClick={approveAssetReq}>Approve</Button>
            </div>
            <DenialConfirmationModal visible={isDenialModalShow} closeModal={closeDenialModal} denyAssetReq={denyAssetReq} denialHeader={"Denial of edit asset"} denialSubheader={denialSubheader}/>
        </div>
    )
}

export default EditDataAssetScreenComp;