import { selectUserEmailsTableData } from '../../../store/project/projectSelectors';
import { useSelector } from 'react-redux';
import { KeycloakService } from '../../../services/KeycloakService';
import { setUserEmailsTableData } from '../../../store/project/projectSlice';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { UsersWithEmailTable } from '../../../core/components';

const UsersTable: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const email = KeycloakService.getEmail();
    const tableData = JSON.parse(JSON.stringify(useSelector(selectUserEmailsTableData)));
    console.log("tableData", tableData);

    const deleteRow = (row:any) => {
        const UpdatedTableData = tableData.filter((item:any) => item.email !== row.email)
        dispatch(setUserEmailsTableData(UpdatedTableData));
    }

    return (
        <UsersWithEmailTable tableData={tableData} email={email} deleteRow={deleteRow}/>
    )
}

export default UsersTable;