import { useFormContext } from "react-hook-form";
import { ControlledDatePicker, ControlledDropdown } from "../../../core/components";
import { assetCriticalityOptions, frequencyOfUseOptions, radioOptions, targetAudienceOptions } from "../../../services/constants";
import { Accordion, AccordionItem, Button } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import { AssetDTO } from "../../../api";
import ControlledRadioGroup from "../../../core/components/ControlledRadioGroup";
import dayjs from "dayjs";
import { KeycloakService } from "../../../services/KeycloakService";

interface DataAssetFieldsProps {
    selectedAsset: AssetDTO
}

type AssetFormFieldsErrors = {
    devMigrationDate: string;
    dataSourcesMigrationDate: string;
    uatMigrationDate: string;
    preProductionMigrationDate: string;
    productionMigrationDate: string;
};

const OptionalEditableFieldsDataAsset: React.FC<DataAssetFieldsProps> = ({ selectedAsset }) => {
    const { formState: { errors } } = useFormContext<AssetFormFieldsErrors>();
    const { setValue, getValues } = useFormContext();
    const [activeKeys, setActiveKeys] = useState<string[]>(['1', '2']);
    const onClickOptionalDetails = (activeKeys: string[]) => {
        setActiveKeys(activeKeys);
    }
    useEffect(() => {
        setValue("assetCriticality", selectedAsset?.assetCriticality);
        setValue("isDevMigrated", selectedAsset?.devEnvCompleted || radioOptions[1].value);
        setValue("isDataSourcesMigrated", selectedAsset?.dataSourcesMigrated || radioOptions[1].value);
        setValue("isUatMigrated", selectedAsset?.uatEnvCompleted || radioOptions[1].value);
        setValue("isPreProductionMigrated", selectedAsset?.prePodEnvCompleted || radioOptions[1].value);
        setValue("isProductionMigrated", selectedAsset?.prodEnvCompleted || radioOptions[1].value);
        setValue("devMigrationDate", selectedAsset.devEnvCompletedDate ? dayjs(new Date(selectedAsset.devEnvCompletedDate)).format("MM/DD/YYYY") : new Date());
        setValue("dataSourcesMigrationDate", selectedAsset.dataSourcesMigratedDate ? dayjs(new Date(selectedAsset.dataSourcesMigratedDate)).format("MM/DD/YYYY") : new Date());
        setValue("uatMigrationDate", selectedAsset.uatEnvCompletedDate ? dayjs(new Date(selectedAsset.uatEnvCompletedDate)).format("MM/DD/YYYY") : new Date());
        setValue("preProductionMigrationDate", selectedAsset.prePodEnvCompletedDate ? dayjs(new Date(selectedAsset.prePodEnvCompletedDate)).format("MM/DD/YYYY") : new Date());
        setValue("productionMigrationDate", selectedAsset.prodEnvCompletedDate ? dayjs(new Date(selectedAsset.prodEnvCompletedDate)).format("MM/DD/YYYY") : new Date());
        setValue("complexity", selectedAsset.assetComplexity);
        setValue("frequency", selectedAsset.frequencyOfUse);
        setValue("targetAudience", selectedAsset.targetAudience);
    }, [setValue, selectedAsset]);
    return (
        <div className="bg-[#F3F3F3] p-4 mt-4">
            <Accordion multiple={false} onClick={onClickOptionalDetails} activeKeys={activeKeys}>
                <AccordionItem templateHeader={() => <div className="text-xl">Supplementary</div>} itemKey="1">
                    <div className="text-[#696969] mb-6">This section is for entering supplementary, optional asset details. These can be saved immediately without requiring verification from a manager.</div>
                    <div className="my-4 flex flex-row gap-2">
                        <div className="w-1/3 text-base text-[#696969]">
                            Development environment migrated
                        </div>
                        <div className="w-1/3">
                            <ControlledRadioGroup name="isDevMigrated" required={true} options={radioOptions} value={getValues().isDevMigrated} />
                        </div>
                        <div className="w-1/3">
                            <ControlledDatePicker onChange={() => console.log("Called")} name="devMigrationDate" disabled={getValues().isDevMigrated !== "yes"} required={getValues().isDevMigrated === "yes"} label="Development environment migrated"
                                error={errors.devMigrationDate} />
                            {errors.devMigrationDate && <p className="text-red-500 mt-2">{errors.devMigrationDate.message}</p>}
                        </div>
                    </div>
                    <div className="my-4 flex flex-row gap-2">
                        <div className="w-1/3 text-base text-[#696969]">
                            Data sources migrated
                        </div>
                        <div className="w-1/3">
                            <ControlledRadioGroup name="isDataSourcesMigrated" required={true} options={radioOptions} value={getValues().isDataSourcesMigrated} />
                        </div>
                        <div className="w-1/3">
                            <ControlledDatePicker onChange={() => console.log("Called")} name="dataSourcesMigrationDate" disabled={getValues().isDataSourcesMigrated !== "yes"} required={getValues().isDataSourcesMigrated === "yes"} label="Data sources migrated"
                                error={errors.dataSourcesMigrationDate} />
                            {errors.dataSourcesMigrationDate && <p className="text-red-500 mt-2">{errors.dataSourcesMigrationDate.message}</p>}
                        </div>
                    </div>
                    <div className="my-4 flex flex-row gap-2">
                        <div className="w-1/3 text-base text-[#696969]">
                            UAT environment migrated
                        </div>
                        <div className="w-1/3">
                            <ControlledRadioGroup name="isUatMigrated" required={true} options={radioOptions} value={getValues().isUatMigrated} />
                        </div>
                        <div className="w-1/3">
                            <ControlledDatePicker onChange={() => console.log("Called")} name="uatMigrationDate" disabled={getValues().isUatMigrated !== "yes"} required={getValues().isUatMigrated === "yes"} label="UAT environment migrated"
                                error={errors.uatMigrationDate} />
                            {errors.uatMigrationDate && <p className="text-red-500 mt-2">{errors.uatMigrationDate.message}</p>}
                        </div>
                    </div>
                    <div className="my-4 flex flex-row gap-2">
                        <div className="w-1/3 text-base text-[#696969]">
                            Pre-production environment migrated
                        </div>
                        <div className="w-1/3">
                            <ControlledRadioGroup name="isPreProductionMigrated" required={true} options={radioOptions} value={getValues().isPreProductionMigrated} />
                        </div>
                        <div className="w-1/3">
                            <ControlledDatePicker onChange={() => console.log("Called")} name="preProductionMigrationDate" disabled={getValues().isPreProductionMigrated !== "yes"} required={getValues().isPreProductionMigrated === "yes"} label="Pre-production environment migrated"
                                error={errors.preProductionMigrationDate} />
                            {errors.preProductionMigrationDate && <p className="text-red-500 mt-2">{errors.preProductionMigrationDate.message}</p>}
                        </div>
                    </div>
                    <div className="my-4 flex flex-row gap-2">
                        <div className="w-1/3 text-base text-[#696969]">
                            Production environment migrated
                        </div>
                        <div className="w-1/3">
                            <ControlledRadioGroup name="isProductionMigrated" required={true} options={radioOptions} value={getValues().isProductionMigrated} />
                        </div>
                        <div className="w-1/3">
                            <ControlledDatePicker onChange={() => console.log("Called")} name="productionMigrationDate" disabled={getValues().isProductionMigrated !== "yes"} required={getValues().isProductionMigrated === "yes"} label="Production environment migrated"
                                error={errors.productionMigrationDate} />
                            {errors.productionMigrationDate && <p className="text-red-500 mt-2">{errors.productionMigrationDate.message}</p>}
                        </div>
                    </div>
                    <div className="my-4">
                        <ControlledDropdown name="assetCriticality" label="Criticality" data={assetCriticalityOptions} required={false} />
                    </div>
                    <div className="my-4">
                        <ControlledDropdown name="complexity" label="Complexity" data={assetCriticalityOptions} required={false} />
                    </div>
                    <div className="my-4">
                        <ControlledDropdown name="frequency" label="Frequency of use" data={frequencyOfUseOptions} required={false} />
                    </div>
                    <div className="my-4">
                        <ControlledDropdown name="targetAudience" label="Target Audience" data={targetAudienceOptions} required={false} />
                    </div>
                    <div className="flex justify-end">
                        <Button kind='primary' disabled={selectedAsset.owner !== KeycloakService.getEmail()} icon="icon-check-hook-outline" type="submit">Save</Button>
                    </div>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

export default OptionalEditableFieldsDataAsset;