import React, { useEffect, useState } from 'react';
import LoginPage from '../../pages/login/LoginPage';
import { KeycloakService } from '../../services/KeycloakService';
import { Provider } from 'react-redux';
import { store } from '../../store';
import { client } from '../../api/services.gen';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NeedsAuth from '../components/NeedsAuth';
import { CreateNewProjectPage, ProjectDataAssetPageAdmin, ProjectLineagePageAdmin, ProjectTeamDetailsPageAdmin, ProjectUseCasePageBusinessUser } from '../../pages/project';
import { Loading } from '@appkit4/react-components';
import { LandingPageAdmin, LandingPageBusinessUser, LandingPageOther } from '../../pages/landing';
import LandingPageManager from '../../pages/landing/LandingPageManager';
import ProjectDetailsPageAdmin from '../../pages/project/ProjectDetailsPageAdmin';
import ProjectEditPageAdmin from '../../pages/project/ProjectEditPageAdmin';
import ProjectDetailsPageManager from '../../pages/project/ProjectDetailsPageManager';
import ProjectTeamDetailsPageAManager from '../../pages/project/ProjectTeamDetailsPageManager';
import ProjectDetailsPageBusinessUser from '../../pages/project/ProjectDetailsPageBusinessUser';
import ProjectAssetRequestPageManager from '../../pages/project/ProjectAssetRequestPageManager';


const App: React.FC = () => {
  const [keycloakInitialized, setKeycloakInitialized] = useState<boolean>(false);
  const setTokenToClientHeader = () => {
    client.setConfig({
      baseUrl: process.env.REACT_APP_API_ENDPOINT,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      parseAs: 'json'
    });
  }
  setTokenToClientHeader();
  useEffect(() => {
    KeycloakService.initializeKeycloak(
      () => {
        setKeycloakInitialized(true);
        setInterval(async () => {
          await KeycloakService.keycloak.updateToken(30);
          localStorage.setItem('token', KeycloakService.keycloak.token || '');
          localStorage.setItem('refreshToken', KeycloakService.keycloak.refreshToken || '');
          setTokenToClientHeader();
        }, 60000);
      },
      () => {
        setKeycloakInitialized(true);
      }
    );
  }, []);

  return (
    <Provider store={store}>
      {keycloakInitialized ? (
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/dashboard"
              element={
                <NeedsAuth>
                  {KeycloakService.isAuthorizedRole("admin") ?
                    <LandingPageAdmin /> :
                    KeycloakService.isAuthorizedRole("business-user") ?
                      <LandingPageBusinessUser />
                      :
                      KeycloakService.isAuthorizedRole("migration-manager") ?
                        <LandingPageManager />
                        :
                        <LandingPageOther />
                  }
                </NeedsAuth>
              }
            />
            <Route path="/project/:projectId"
              element={
                <NeedsAuth>
                  {KeycloakService.isAuthorizedRole("admin") ?
                    <ProjectDetailsPageAdmin />
                    :
                    KeycloakService.isAuthorizedRole("migration-manager") ?
                      <ProjectDetailsPageManager />
                      :
                      KeycloakService.isAuthorizedRole("business-user") ?
                        <ProjectDetailsPageBusinessUser />
                        :
                        <LandingPageOther />
                  }
                </NeedsAuth>
              }
            />
            <Route path="/project/:projectId/users"
              element={
                <NeedsAuth>
                  {KeycloakService.isAuthorizedRole("admin") ?
                    <ProjectTeamDetailsPageAdmin />
                    :
                    <ProjectTeamDetailsPageAManager />
                  }
                </NeedsAuth>
              }
            />
            <Route path="/project/:projectId/edit"
              element={
                <NeedsAuth>
                  <ProjectEditPageAdmin />
                </NeedsAuth>
              }
            />
            <Route path="/project/:projectId/lineage"
              element={
                <NeedsAuth>
                  <ProjectLineagePageAdmin />
                </NeedsAuth>
              }
            />
            <Route path="/project/:projectId/dataAsset"
              element={
                <NeedsAuth>
                  {KeycloakService.isAuthorizedRole("admin") &&
                    <ProjectDataAssetPageAdmin />
                  }
                </NeedsAuth>
              }
            />
            <Route path="/project/:projectId/useCase/"
              element={
                <NeedsAuth>
                  {KeycloakService.isAuthorizedRole("business-user") &&
                    <ProjectUseCasePageBusinessUser />
                  }
                </NeedsAuth>
              }
            />
            <Route path="/project/:projectId/requests"
              element={
                <NeedsAuth>
                  {KeycloakService.isAuthorizedRole("migration-manager") &&
                    <ProjectAssetRequestPageManager />
                  }
                </NeedsAuth>
              }
            />
            <Route path="/createProject"
              element={
                <NeedsAuth>
                  <CreateNewProjectPage />
                </NeedsAuth>
              }
            /><Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      ) : (
        <Loading loadingType='circular' indeterminate={true} compact={false}></Loading>
      )}
    </Provider>
  );
};

export default App;