import { Button, Modal } from "@appkit4/react-components";
import RequestFormProvider from "./RequestFormProvider";
import { ControlledTextArea } from "../../../core/components";
import { useFormContext } from "react-hook-form";

interface DenialConfirmationModalProps {
    visible: boolean;
    closeModal: Function;
    denyAssetReq: any;
    denialHeader: string;
    denialSubheader: string;
}

type DenialConfirmationFormErrors = {
    denialRequestComment: string;
};

const DenialConfirmationModal: React.FC<DenialConfirmationModalProps> = ({ visible, closeModal, denyAssetReq, denialHeader, denialSubheader }) => {
    return (
        <Modal
            visible={visible}
            title={denialHeader}
            onCancel={closeModal}
            modalStyle={{ width: '33.75rem' }}
            bodyStyle={{ minHeight: '92px' }}
        >
            <RequestFormProvider onSubmit={denyAssetReq}>
                <DenialConfirmationForm subheader={denialSubheader} closeModal={closeModal}/>
            </RequestFormProvider>
        </Modal>);
}

const DenialConfirmationForm: React.FC<{subheader: string, closeModal: Function}> = ({subheader, closeModal}) => {
    const { formState: { errors } } = useFormContext<DenialConfirmationFormErrors>();
    return (
        <div>
            <div className="font-small text-sm text-[#696969]">{subheader}</div>
            <div className="my-4">
                <ControlledTextArea name="denialRequest" required={true} label="Comment" error={errors.denialRequestComment} maxLength={420} />
                {errors.denialRequestComment && <p className="!text-red-500 mt-2">{errors.denialRequestComment.message}</p>}
            </div>
            <div className="my-4 flex justify-end gap-2">
                <Button kind="secondary" onClick={closeModal}>Cancel</Button>
                <Button kind="primary" type="submit">Proceed</Button>
            </div>
        </div>
    )
}

export default DenialConfirmationModal;