import { Breadcrumb, BreadcrumbItem, Button, Loading, Panel } from "@appkit4/react-components";
import { useSelector } from "react-redux";
import { selectProjectConfigData, selectProjectError, selectProjectLoading, selectScopeDataOverView, selectSelectedProjectData } from "../../../store/project/projectSelectors";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { getProjectsList, pullNewLineageForProject } from "../../../store/project/projectThunks";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { scopeOverviewKeyLabel } from "../../../services/constants";
import { useState } from "react";

const LineageModelContainer = () => {
    const [isNewAssetsSectionVisible, setIsNewAssetSectionVisible] = useState(false)
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const selectedProject = useSelector(selectSelectedProjectData);
    const projectConfigData = useSelector(selectProjectConfigData);
    const scopeOverviewData = useSelector(selectScopeDataOverView);
    const loading = useSelector(selectProjectLoading);
    const error = useSelector(selectProjectError);
    
    const pullLineage = () => {
        dispatch(pullNewLineageForProject(selectedProject?.projectBusinessId));
        setIsNewAssetSectionVisible(true)
    }

    const dataAssetsDetails = [
        {
            name: "AI Model",
            icon: "bot",
            noOfAssets: scopeOverviewData?.aiModel
        },
        {
            name: "Applications",
            icon: "box",
            noOfAssets: scopeOverviewData?.application
        },
        {
            name: "Dashboards",
            icon: "dashboard",
            noOfAssets: scopeOverviewData?.dashboard
        },
        {
            name: "Operational Datafeed",
            icon: "group",
            noOfAssets: scopeOverviewData?.operationalDatafeed
        },
        {
            name: "Reports",
            icon: "document-text",
            noOfAssets: scopeOverviewData?.report
        },
        {
            name: "SAS Scripts",
            icon: "query",
            noOfAssets: scopeOverviewData?.sasScript
        },
    ]
    return (
        <div className="m-8">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => {
                        dispatch(getProjectsList()); 
                        navigate("/dashboard")
                    }}>All Projects</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => navigate(`/project/${selectedProject?.projectBusinessId}`)}>{selectedProject?.name}</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    Lineage model
                </BreadcrumbItem>
            </Breadcrumb>
            <div className="flex justify-between mt-4">
                <h1 className="font-sans font-medium text-xl mb-4">Lineage Model </h1>
                <Button kind='primary' icon="icon-upload-outline" onClick={pullLineage} disabled={loading}>Pull lineage</Button>
            </div>
            <Panel title={"Pull available lineage model"} className="mt-4">
                <div className="py-2">You can pull the available lineage model using the trigger button. During the migration, you will have the ability to access additional lineage modes or add any pending ones.</div>
                {loading &&
                    <div className="text-center">
                        <Loading loadingType='circular' indeterminate={true} compact={false}></Loading>
                        <div>The data assets are being imported. This can take some time.</div>
                    </div>
                }
            </Panel>
            {isNewAssetsSectionVisible && !loading &&
                <div className="flex mt-4">
                    <Panel className="flex-1">
                        <div className="flex justify-between pt-8">
                            <div className="text-[#415385]">
                                <span className={`Appkit4-icon icon-information-outline ap-font-24 mr-1`} aria-hidden="true"></span>A new lineage model has just been pulled.
                            </div>
                            <div>
                                <span className={`Appkit4-icon icon-close-outline ap-font-24 cursor-pointer`} aria-hidden="true" onClick={()=> setIsNewAssetSectionVisible(false)}></span>
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <div className="mt-4">
                                <h1 className="font-medium text-xl py-2">Current version</h1>
                                <p><span className="font-bold mr-1">{scopeOverviewData?.totalAssets}</span>data assets available</p>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-between">
                            {dataAssetsDetails?.map((dataAsset, index) =>
                                <div className="w-1/3 lg:w-1/3 sm:w-1/2 mt-4 px-2" key={index}>
                                    <Panel className="bg-gray-100">
                                        <div className="font-medium text-xl py-2">{dataAsset.name}</div>
                                        <span className={`Appkit4-icon icon-${dataAsset.icon}-outline ap-font-24`} aria-hidden="true"></span>
                                        <span className="ml-2 font-bold text-4xl">{dataAsset.noOfAssets}</span>
                                    </Panel>
                                </div>
                            )}
                        </div>
                    </Panel>
                </div>
            }
            {projectConfigData?.config?.lineagePulled &&
                <div className="bg-white mt-4 py-2 px-4">
                    <div className="flex justify-between mt-4">
                        <h1 className="font-sans font-medium text-xl mb-4">Current data assets overview</h1>
                        <div className="w-fit mr-4 mt-2">
                            <span className={`Appkit4-icon icon-update-outline ap-font-24 mr-2`} aria-hidden="true"></span>
                            Last updated lineage on {dayjs(new Date(projectConfigData?.config?.lineagePulledUpdatedDate || "")).format("MM/DD/YYYY")}
                        </div>
                    </div> 
                    <div className="w-full border-b-4 py-2">
                        <span className={`Appkit4-icon icon-table-data-fill ap-font-16 font-bold py-1 w-fit gap-2 ml-2`} aria-hidden="true">Data assets overview</span>
                    </div>
                    {Object.entries(scopeOverviewData).map(([key, value]) => (
                        key !== "totalAssets" &&
                            <div className="flex justify-between border-b-2 p-2" key={key}>
                                <div>{scopeOverviewKeyLabel?.filter(keyLabel=>keyLabel.value === key)[0].label}</div>
                                <div>{key === "lastUpdated" ? dayjs(new Date(value)).format("MM/DD/YYYY") : value}</div>
                            </div>
                    ))}
                    <div className="flex justify-between border-b-2 p-2">
                        <div className="font-bold">Total</div>
                        <div>{scopeOverviewData?.totalAssets}</div>
                    </div>
                </div>
            }
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>);
}

export default LineageModelContainer;