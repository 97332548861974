import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { AssetType } from '../../../api';

type DataAssetFieldsFormValues = {
  assetName: string;
  assetType: AssetType;
  assetDescription: string;
  createdBy: string;
  createdDate: Date;
  updatedBy: string;
  updatedDate: Date;
  environment: string;
};

type DataAssetsFieldsFormProviderProps = {
  onSubmit: SubmitHandler<DataAssetFieldsFormValues>;
  children: React.ReactNode;
};

const DataAssetFieldsFormProvider: React.FC<DataAssetsFieldsFormProviderProps> = ({ onSubmit, children }) => {
  const methods = useForm<DataAssetFieldsFormValues>();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default DataAssetFieldsFormProvider;
