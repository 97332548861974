import React from 'react';
import backGroundImage from "../../assets/images/background.svg";
import { LoginContainer } from '../../containers/login';
import FooterApp from '../../core/components/Footer';
import HeaderApp from '../../core/components/Header';
import { Button } from '@appkit4/react-components';

const LoginPage: React.FC = () => {

    const backgroundImageStyle = {
        backgroundImage: `url(${backGroundImage})`,
        backgroundRepeat: 'no-repeat'
    };
    const url = 'https://yoda.pwc.de/files/2928503d-2036-4736-86cc-4189c302c777/b0d8aab2-41fc-4ef6-a7b2-edac9d6f0ea9/Yoda_ Datenschutzhinweise für PwC Mitarbeitende & Partner_0624 pdf';
    return (
        <div className="flex">
            <div className="flex flex-col flex-1">
                <HeaderApp />
                <main className="flex flex-grow container items-center justify-center">
                    <LoginContainer />
                </main>
                <FooterApp />
            </div>
            <div style={backgroundImageStyle} className="flex-1 h-screen w-full bg-contain bg-right flex justify-end">
                <Button className="absolute bottom-2 mr-4" kind="text" onClick={() => window.open(url, '_blank')}>Privacy statement</Button>
            </div>
        </div>
    );
};

export default LoginPage;