import { Input } from "@appkit4/react-components";

const LineageModelDetails: React.FC<{ gcsBucket: string, setGCSBucket: Function, bigQueryDataSetID: string, setBigQueryDataSetID: Function }> = (
  { gcsBucket, bigQueryDataSetID, setGCSBucket, setBigQueryDataSetID }) => {

  return (
    <div className="my-2">
      <div className="my-4 w-3/4">
        <Input name="gcsBucket" title="GCS Bucket" value={gcsBucket} onChange={setGCSBucket} />
      </div>
      <div className="my-2 w-3/4">
        <Input name="bigQueryDataSetID" title="BigQuery Dataset ID" value={bigQueryDataSetID} onChange={setBigQueryDataSetID} />
      </div>
    </div>
  )
}

export default LineageModelDetails;