import { Button, Checkbox, CheckboxGroup, Search, Tag } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { selectOldTablesValuesOptions, selectTablesValuesOptions } from "../../../store/project/projectSelectors";
import { useSelector } from "react-redux";
import { getMasterDataByType } from "../../../store/project/projectThunks";
import { setOldTablesOptions } from "../../../store/project/projectSlice";

const TableSelectionSection: React.FC<{ setActiveIndex: Function, submitBtnValue: string, submitAssetReqFromTable: Function, tablesValue: Array<string | number>, setTablesValue: Function }> = ({ setActiveIndex, submitBtnValue, submitAssetReqFromTable, tablesValue, setTablesValue }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [pageNumberTable, setPageNumberTable] = useState(1);
    const tablesValuesOptions = useSelector(selectTablesValuesOptions);
    const oldTableOptions = useSelector(selectOldTablesValuesOptions);
    const [searchText, setSearchText] = useState("");
    const handleSearch = (e: string) => {
        dispatch(setOldTablesOptions([]));
        setPageNumberTable(0);
        setSearchText(e);
    }
    const onChange = (value: (string | number)[]) => {
        setTablesValue(value as Array<string>);
    };
    const handleScroll = (event: any) => {
        const target = event?.target;
        if (target.scrollHeight - target.scrollTop === target.clientHeight && tablesValuesOptions?.data?.length === 10) {
            dispatch(setOldTablesOptions(tablesValuesOptions?.data))
            setPageNumberTable(pageNumberTable + 1)
        }
    }
    let options = JSON.parse(JSON.stringify(tablesValuesOptions?.data))
    if (tablesValuesOptions && tablesValuesOptions.data && tablesValuesOptions?.data?.length > 0 && oldTableOptions?.length > 0) {
        options = [...oldTableOptions, ...options];
    }
    useEffect(() => {
        dispatch(getMasterDataByType({ masterDataType: 'tables', pageNumber: pageNumberTable, pageSize: '10', searchString: searchText }));
    }, [dispatch, pageNumberTable, searchText]);

    return (
        <div className="p-4">
            <div className="bg-white border-2 p-2 text-[#696969]">
                {tablesValue?.length > 0 ?
                    <>
                        <div>Tables</div>
                        <div className="flex gap-0.5 flex-wrap">
                            {tablesValue.map((selectedTable, index) =>
                                <Tag key={index} type='filled' onClose={() => setTablesValue(tablesValue.filter((table) => table !== selectedTable))}>{options?.filter((table: { id: string, name: string }) => table.id === selectedTable)?.[0]?.name}</Tag>
                            )}
                        </div>
                    </>
                    :
                    <div>Select the table</div>
                }
            </div>
            <Search className="px-0 w-full"
                onSearch={handleSearch} placeholder="Search" searchValue={searchText} onChange={handleSearch} />
            <div className="border-2 p-4 h-72 overflow-auto" onScroll={handleScroll}>
                <div className="py-4 font-bold">Name</div>
                <CheckboxGroup value={tablesValue} name="table" onChange={onChange}>
                    {options?.map((table: { id: string, name: string }) => <Checkbox className="p-2" value={table?.id}>{table?.name}</Checkbox>)}
                </CheckboxGroup>
            </div>
            <div className="flex justify-end gap-2 mt-4">
                <Button kind='secondary' onClick={() => setActiveIndex(1)}>Back</Button>
                <Button kind='primary' icon={submitBtnValue === "Submit request" ? "icon-check-hook-outline" : "icon-arrow-right-small-outline"} disabled={tablesValue?.length <= 0} onClick={submitAssetReqFromTable}>{submitBtnValue}</Button>
            </div>

        </div>
    )
}

export default TableSelectionSection;