
import React from 'react';
import { ProjectDTO } from '../../api';
import { LandingBusinessUser } from '../../components/Landing';
import { selectProjectError } from '../../store/project/projectSelectors';
import { useSelector } from 'react-redux';

const LandingContainerBusinessUser: React.FC<{goToViewProject: (project:ProjectDTO) => void}> = ({goToViewProject}) => {
    const error = useSelector(selectProjectError);
    return (
        <>
            <LandingBusinessUser goToViewProject={goToViewProject}/>
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </>
    )
}

export default LandingContainerBusinessUser;