import { Button, Modal, Select } from "@appkit4/react-components";
import AddAssetFormProvider from "./AddAssetFormProvider";
import { assetTypeOptions } from "../../../services/constants";
import { AssetType } from "../../../api";
import React, { useState } from "react";
import ModalContentForAIModelScript from "./ModalContentForAIModelScript";
import ModalContentForReportDashboard from "./ModalContentForReportDashboard";
import ModalContentForApplications from "./ModalContentForApplications";

interface AddAssetsModalProps {
    visible: boolean;
    closeModal: Function;
    addAsset: Function;
}

const AddDataAssetModal: React.FC<AddAssetsModalProps> = ({ visible, closeModal, addAsset }) => {
    const [selectedAssetType, setSelectedAssetType] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);
    const addAssetData = async (data: {
        assetName: string;
        assetType: AssetType;
        assetDescription: string;
        environment: string;
    }) => {
        await addAsset(data);
        setSelectedAssetType("");
        setActiveIndex(0);
    };
    const submitAddAsset = async (formValues: any, databasesValue: Array<string | number>, tablesValue: Array<string | number>) => {
        const data ={
            assetName: formValues?.assetName,
            assetType: formValues?.assetType,
            assetDescription: formValues?.assetDescription,
            environment: formValues?.environment,
            databaseValue: databasesValue,
            tableValue: tablesValue
        }
        await addAsset(data);
        setSelectedAssetType("");
        setActiveIndex(0);
    }
    const submitAddAssetForApp = async (formValues: any, databasesValue: Array<string | number>, tablesValue: Array<string | number>, technologyValue: Array<string | number>) => {
        const data ={
            assetName: formValues?.assetName,
            assetType: formValues?.assetType,
            assetDescription: formValues?.assetDescription,
            environment: formValues?.environment,
            databaseValue: databasesValue,
            tableValue: tablesValue,
            technologyValue: technologyValue
        }
        await addAsset(data);
        setSelectedAssetType("");
        setActiveIndex(0);
    }
    const addAssetDataForStepper = () => {
        setActiveIndex(1);
    }
    const backClicked = () => {
        setSelectedAssetType("");
    }
    const nextClicked = (assetType: string) => {
        setSelectedAssetType(assetType);
    }
    const closeModalChild = () => {
        setSelectedAssetType("");
        setActiveIndex(0);
        closeModal();
    }
    return (
        <Modal
            visible={visible}
            header={
                <div className="flex-col">
                    <h1 className="font-sans font-medium text-xl mb-4">Add new asset</h1>
                    <div className="font-small text-sm text-[#696969]">Before proceeding, you need to select the asset type. This is necessary to ensure that the details form accordingly.</div>
                </div>}
            onCancel={closeModalChild}
            modalStyle={{ width: '42rem' }}
            bodyStyle={{ minHeight: '92px', maxHeight: "fit-content"}}
        >
            {!selectedAssetType ?
                <AddAssetType closeModal={closeModalChild} nextClicked={nextClicked}/>
                :
                <>
                    <AddAssetFormProvider onSubmit={addAssetData}>
                        {(selectedAssetType === 'report' || selectedAssetType === 'dashboard' || selectedAssetType === 'operationalDatafeed') &&
                            <ModalContentForReportDashboard assetType={selectedAssetType} backClicked={backClicked} submitBtnValue={"Submit request"}/>
                        }
                    </AddAssetFormProvider>
                    <AddAssetFormProvider onSubmit={addAssetDataForStepper}>
                        {(selectedAssetType === 'aiModel' || selectedAssetType === 'sasScript') &&
                            <ModalContentForAIModelScript assetType={selectedAssetType} backClicked={backClicked} activeIndex={activeIndex} setActiveIndex={setActiveIndex} submitAddAsset={submitAddAsset}/>
                        }
                    </AddAssetFormProvider>
                    <AddAssetFormProvider onSubmit={addAssetDataForStepper}>
                        {(selectedAssetType === 'application') &&
                            <ModalContentForApplications assetType={selectedAssetType} backClicked={backClicked} activeIndex={activeIndex} setActiveIndex={setActiveIndex} submitAddAsset={submitAddAssetForApp}/>
                        }
                    </AddAssetFormProvider>
                </>
            }
        </Modal>);
}

const AddAssetType: React.FC<{ closeModal: Function, nextClicked: Function}> = ({ closeModal, nextClicked }) => {
    const [assetType, setAssetType] = useState();
    const selectAssetType = (assetTypeValue: any) => {
        setAssetType(assetTypeValue);
    }
    const clickNext = () => {
        nextClicked(assetType);
    }
    return(
        <>
            <div className="my-4">
                <Select placeholder="Asset type" data={assetTypeOptions} required={true} value={assetType} onSelect={selectAssetType} dropdownRenderMode="portal"/>
            </div>
            <div className="flex justify-end gap-2">
                <Button kind='secondary' onClick={closeModal}>Cancel</Button>
                <Button kind='primary' icon="icon-arrow-right-small-outline" disabled={!assetType} onClick={clickNext}>Next</Button>
            </div>
        </>
    )
}

export default AddDataAssetModal;