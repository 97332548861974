import { Button, Modal, Select } from "@appkit4/react-components";
import { useFormContext } from "react-hook-form";
import { ControlledDatePicker, ControlledInput, ControlledTextArea } from "../../../core/components";
import { useEffect, useState } from "react";
import { typeData } from "../mockData";
import EditProjectFormProvider from "./EditProjectFormProvider";
import { ProjectDTO } from "../../../api";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { EditProjectData } from "../../../store/project/projectThunks";

interface EditProjectConfModalProps {
    visible: boolean;
    closeModal: Function;
    selectedProject: ProjectDTO;
}

type EditProjectFormErrors = {
    name: string;
    type: string;
    startDate: string;
    endDate: string;
    description: string;
};

const EditProjectDetailsModal: React.FC<EditProjectConfModalProps> = ({ visible, closeModal, selectedProject }) => {
    const dispatch = useDispatch<AppDispatch>();
    const editProjectData = (data: {
        name: string;
        type: string;
        startDate: Date;
        endDate: Date;
        description: string;
    }) => {
        closeModal();
        const projectDetails = {
            name: data?.name,
            type: "dmw-type",
            startDate: new Date(`${data?.startDate} UTC`).toISOString(),
            endDate: new Date(`${data?.endDate} UTC`).toISOString(),
            description: data?.description,
            projectTechnicalId: selectedProject.projectTechnicalId,
            projectBusinessId: selectedProject.projectBusinessId
        }
        dispatch(EditProjectData(projectDetails))
    };
    return (
        <Modal
            visible={visible}
            title={"Project details"}
            onCancel={closeModal}
            modalStyle={{ width: '44rem' }}
            bodyStyle={{ minHeight: '92px' }}
        >
            <EditProjectFormProvider onSubmit={editProjectData}>
                <ModalContent closeModal={closeModal} selectedProject={selectedProject}/>
            </EditProjectFormProvider>
        </Modal>);
}

const ModalContent:React.FC<{closeModal: Function, selectedProject:ProjectDTO}> = ({closeModal, selectedProject}) => {
    const { formState: { errors }, setValue } = useFormContext<EditProjectFormErrors>();
    const [startDateValue, setStartdateValue] = useState();
    const [endDateValue, setEndDateValue] = useState(); 
    useEffect(() => {
        setValue("name", selectedProject?.name);
        setValue("type", selectedProject?.type);
        setValue("startDate", dayjs(new Date(selectedProject?.startDate)).format("MM/DD/YYYY"));
        setValue("endDate", dayjs(new Date(selectedProject?.endDate)).format("MM/DD/YYYY"));
        setValue("description", selectedProject?.description);
    },[setValue, selectedProject]);
    return(
        <div>
            <div className="my-4">
                <ControlledInput name="name" required={true} type="name" label="Name" error={errors.name} />
                {errors.name && <p className="!text-red-500 mt-2">{errors.name.message}</p>}
            </div>
            <div className="my-4">
                <Select disabled value="dmw-type" placeholder="Type" data={typeData} />
            </div>
            <div className="flex mb-4 mt-4 justify-between gap-2">
                <div className="flex-col w-full">
                    <ControlledDatePicker name="startDate" required={true} label="Start Date" 
                    error={errors.startDate} onChange={setStartdateValue} maxDate={endDateValue ? endDateValue : undefined}/>
                    {errors.startDate && <p className="!text-red-500 mt-2">{errors.startDate.message}</p>}
                </div>
                <div className="flex-col w-full">
                    <ControlledDatePicker name="endDate" required={true} label="End Date" 
                        error={errors.endDate} onChange={setEndDateValue} minDate={startDateValue ? startDateValue : undefined}/>
                    {errors.endDate && <p className="!text-red-500 mt-2">{errors.endDate.message}</p>}
                </div>
            </div>
            <div className="my-4">
                <ControlledTextArea name="description" required={true} label="Description" error={errors.description} maxLength={420} />
                {errors.description && <p className="!text-red-500 mt-2">{errors.description.message}</p>}
            </div>
            <div className="flex justify-end gap-2">
                <Button kind='secondary' onClick={closeModal}>Cancel</Button>
                <Button kind='primary' icon="icon-check-hook-outline" type="submit">Save</Button>
            </div>
        </div>
    )
}

export default EditProjectDetailsModal;