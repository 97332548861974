import { useFormContext } from "react-hook-form";
import { ControlledDropdown, ControlledInput } from "../../../core/components";
import { assetTypeOptions, environmentOptions } from "../../../services/constants";
import { Accordion, AccordionItem, Button } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import { AssetDTO } from "../../../api";
import dayjs from "dayjs";

interface DataAssetFieldsProps {
    selectedAsset: AssetDTO
}

type AssetFormFieldsErrors = {
    assetName: string;
    assetType: string;
    environment: string;
};

const EditableFieldsDataAsset: React.FC<DataAssetFieldsProps> = ({ selectedAsset }) => {
    const { formState: { errors } } = useFormContext<AssetFormFieldsErrors>();
    const { setValue } = useFormContext();
    const [activeKeys, setActiveKeys] = useState<string[]>(['1', '2']);
    const onClickOptionalDetails = (activeKeys: string[]) => {
        setActiveKeys(activeKeys);
    }
    useEffect(() => {
        setValue("assetName", selectedAsset?.assetName || "");
        setValue("assetType", selectedAsset?.assetType || "");
        setValue("assetDescription", selectedAsset?.assetDescription);
        setValue("createdBy", selectedAsset?.createdBy);
        setValue("createdDate", selectedAsset?.createdAt ? dayjs(new Date(selectedAsset.createdAt)).format("MM/DD/YYYY") : "");
        setValue("updatedBy", selectedAsset?.updatedBy);
        setValue("updatedDate", selectedAsset.updatedAt ? dayjs(new Date(selectedAsset.updatedAt)).format("MM/DD/YYYY") : "");
        setValue("environment", selectedAsset?.environment);
    }, [setValue, selectedAsset]);
    return (
        <div className="bg-[#F3F3F3] p-4 mb-4">
            <Accordion multiple={false} onClick={onClickOptionalDetails} activeKeys={activeKeys}>
                <AccordionItem templateHeader={() => <div className="text-xl">Required details</div>} itemKey="1">
                    <div className="text-[#696969] mb-6">Here you will find the main asset details that are automatically populated. These mandatory details will need manager verification if any changes are made.</div>
                    <div className="my-4">
                        <ControlledInput name="assetName" required={true} type="text" label="Asset name" error={errors.assetName} />
                        {errors.assetName && <p className="!text-red-500 mt-2">{errors.assetName.message}</p>}
                    </div>
                    <div className="my-4">
                        <ControlledDropdown name="assetType" label="Asset type" error={errors.assetType} data={assetTypeOptions} required={true} />
                        {errors.assetType && <p className="!text-red-500 mt-2">{errors.assetType.message}</p>}
                    </div>
                    <div className="my-4">
                        <ControlledInput name="assetDescription" required={false} type="text" label="Asset description" />
                    </div>
                    <div className="my-4">
                        <ControlledInput disabled={true} name="createdBy" required={false} type="text" label="Created By" />
                    </div>
                    <div className="my-4">
                        <ControlledInput disabled={true} name="createdDate" required={false} type="text" label="Created Date" />
                    </div>
                    <div className="my-4">
                        <ControlledInput disabled={true} name="updatedBy" required={false} type="text" label="Updated By" />
                    </div>
                    <div className="my-4">
                        <ControlledInput disabled={true} name="updatedDate" required={false} type="text" label="Updated Date" />
                    </div>
                    <div className="my-4">
                        <ControlledDropdown name="environment" label="Environment" error={errors.environment} data={environmentOptions} required={true} />
                        {errors.environment && <p className="!text-red-500 mt-2">{errors.environment.message}</p>}
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button kind='primary' disabled={!selectedAsset?.assetActiveStatus || selectedAsset?.assetStatus === "pendingForReview"} icon="icon-plus-outline" type="submit">{!(selectedAsset?.assetStatus === "pendingForReview") ? 'Submit request' : 'Waiting for approval'}</Button>
                    </div>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

export default EditableFieldsDataAsset;