import { Button, Input, Panel, TextArea } from "@appkit4/react-components";
import DenialConfirmationModal from "./DenialConfirmationModal";
import { useState } from "react";
import { RequestDTO } from "../../../api";

const ActionOnAssetRequest:React.FC<{selectedAssetReqeust: RequestDTO, actionOnRequest: Function, reviewType: string}> = ({selectedAssetReqeust, actionOnRequest, reviewType}) => {
    const [isDenialModalShow, setIsDenialModalShow] = useState(false);
    let requestHeader = "";
    let requestSubheader = "";
    let denialHeader = "";
    let denialSubheader = "";
    if(reviewType === "removeOwnership") {
        denialHeader = "Denial of asset removal";
        denialSubheader = "After reviewing, if you decide to deny the request, the asset will remain in place. The applicant will be notified of your decision with the comment below."
        requestHeader = "Asset removal";
        requestSubheader = "The following user has requested for removal of the asset. Please review provided reason from the user and deny or approve the request."
    }
    if(reviewType === "ownershipOfAsset") {
        denialHeader = "Denial of ownership of an asset";
        denialSubheader = "Please review the request thoroughly. If the applicant does not need ownership of this asset, please justify the reason. The user will be notified."
        requestHeader = "Ownership of an asset";
        requestSubheader = "The following user has requested for ownership of the asset. Please review provided reason from the user and deny or approve the request."
    }
    const closeDenialModal = () => {
        setIsDenialModalShow(false);
    }
    const denyAssetReq = (data: any) => {
        setIsDenialModalShow(false);
        actionOnRequest({requestTechnicalId: selectedAssetReqeust?.requestTechnicalId, requestBusinessId: selectedAssetReqeust?.requestBusinessId, actionType: "deny", actionComment: data?.denialRequest})
    };
    const approveAssetReq = () => {
        actionOnRequest({requestTechnicalId: selectedAssetReqeust?.requestTechnicalId, requestBusinessId: selectedAssetReqeust?.requestBusinessId, actionType: "approved"})
    }
    return(
        <>
        <Panel title={requestHeader} >
            <div className="flex flex-row gap-2 justify-between">
                <span className="text-sm">{requestSubheader}</span>
            </div>
            <Input title="Asset name" className="my-4" value={selectedAssetReqeust?.assetName} readonly/>
            <Input title="Requested by" className="my-4" value={selectedAssetReqeust?.requestBy} readonly/>
            <TextArea title="Request description" className="my-4" value={selectedAssetReqeust?.requestDescription} readonly/>
            <div className="flex justify-end gap-2">
                <Button kind='secondary' icon="icon-close-outline" onClick={() => setIsDenialModalShow(true)}>Deny</Button>
                <Button kind='primary' icon="icon-check-hook-outline" onClick={approveAssetReq}>Approve</Button>
            </div>
        </Panel>
        <DenialConfirmationModal visible={isDenialModalShow} closeModal={closeDenialModal} denyAssetReq={denyAssetReq} denialHeader={denialHeader} denialSubheader={denialSubheader}/>
        </>
    )
}

export default ActionOnAssetRequest;