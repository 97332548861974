import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { AssetComplexity, AssetCriticality, FrequencyOfUse, MigratedEnvStatus, TargetAudience } from '../../../api';

type DataAssetOptFieldsFormValues = {
    assetCriticality: AssetCriticality;
    complexity: AssetComplexity;
    frequency: FrequencyOfUse;
    targetAudience: TargetAudience;
    isDevMigrated: MigratedEnvStatus;
    devMigrationDate: Date;
    isDataSourcesMigrated: MigratedEnvStatus;
    dataSourcesMigrationDate: Date;
    isUatMigrated: MigratedEnvStatus;
    uatMigrationDate: Date;
    isPreProductionMigrated: MigratedEnvStatus;
    preProductionMigrationDate: Date;
    isProductionMigrated: MigratedEnvStatus;
    productionMigrationDate: Date;
};

type DataAssetsOptFieldsFormProviderProps = {
  onSubmit: SubmitHandler<DataAssetOptFieldsFormValues>;
  children: React.ReactNode;
};

const DataAssetOptFieldsFormProvider: React.FC<DataAssetsOptFieldsFormProviderProps> = ({ onSubmit, children }) => {
  const methods = useForm<DataAssetOptFieldsFormValues>();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default DataAssetOptFieldsFormProvider;
