import React, { useEffect, useState } from 'react';
import { LoggedInFooter, LoggedInHeader } from '../../core/components';
import { Navigation, NavigationItem } from '@appkit4/react-components/navigation';
import { getProjectConfigData, getProjectDetails, getScopeData } from '../../store/project/projectThunks';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import { navigationItemsWithProject } from '../../services/constants';
import { LineageModelContainer } from '../../containers/project';
import { useNavigate, useParams } from 'react-router-dom';
import { selectSelectedProjectData } from '../../store/project/projectSelectors';
import { useSelector } from 'react-redux';

const ProjectLineagePageAdmin: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { projectId } = useParams();
    const [selectedKey, setSelectedKey] = useState('3');
    const [collapsed, setCollapsed] = useState(true);
    const selectedProject = useSelector(selectSelectedProjectData);
    const onCollapseEvent = (collapsed: boolean, event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
        setCollapsed(collapsed);
    }
    useEffect(() => {
        if(projectId){
            dispatch(getProjectDetails(projectId));
            dispatch(getProjectConfigData(projectId));
            dispatch(getScopeData(projectId));
        }
    }, [dispatch, projectId]);
    const navList: NavigationItem[] = navigationItemsWithProject;
    const renderContent = (selectedKey: string) => {
        switch (selectedKey) {
            case '0':
                navigate(`/dashboard`);
                break;
            case '1':
                navigate(`/project/${selectedProject?.projectBusinessId}`);
                break;
            case '2':
                navigate(`/project/${selectedProject?.projectBusinessId}/users`);
                break;
            case '3':
                return <LineageModelContainer />
            // case '4':
            //     navigate(`/project/${selectedProject?.projectBusinessId}/dataAsset`);
            //     break;
            case '4':
                navigate(`/project/${selectedProject?.projectBusinessId}/edit`);
                break;
            default:
                return <div>Unknown status</div>;
        }
    };
    return (
        <div className="h-screen bg-[#F3F3F3]">
            <LoggedInHeader />
            <div className="flex bg-[#F3F3F3]">
                    <Navigation
                        collapsed={collapsed}
                        width={280}
                        className="sitedemo"
                        navList={navList}
                        hasLogo={false}
                        selectedKey={selectedKey}
                        style={{ height: '93vh' }}
                        onClickCollapseEvent={onCollapseEvent}
                        onItemClick={(event: any, item: NavigationItem, key: string) => {
                            setSelectedKey(key);
                        }}>
                    </Navigation>

                <div className="flex flex-col w-full">
                    <div className='flex-1'>
                        {renderContent(selectedKey)}
                    </div>
                    <LoggedInFooter />
                </div>
            </div>
        </div>
    );
};

export default ProjectLineagePageAdmin;