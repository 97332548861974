import { Button, Checkbox, CheckboxGroup, Search, Tag } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { selectDatabasesValuesOptions, selectOldDatabaseValuesOptions } from "../../../store/project/projectSelectors";
import { useSelector } from "react-redux";
import { getMasterDataByType } from "../../../store/project/projectThunks";
import { setOldDatabasesOptions } from "../../../store/project/projectSlice";

const DatabaseSelectionSection: React.FC<{ setActiveIndex: Function, databasesValue: Array<string | number>, setDatabasesValue: Function }> = ({ setActiveIndex, databasesValue, setDatabasesValue }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [pageNumber, setPageNumber] = useState(1);
    const databasesValuesOptions = useSelector(selectDatabasesValuesOptions);
    const oldDatabaseOptions = useSelector(selectOldDatabaseValuesOptions);
    const [searchText, setSearchText] = useState("");
    const handleSearch = (e: string) => {
        dispatch(setOldDatabasesOptions([]))
        setPageNumber(0);
        setSearchText(e);
    }
    const onChange = (value: (string | number)[]) => {
        setDatabasesValue(value as Array<string>);
    };
    const handleScroll = (event: any) => {
        const target = event?.target;
        if (target.scrollHeight - target.scrollTop === target.clientHeight && databasesValuesOptions?.data?.length === 10) {
            dispatch(setOldDatabasesOptions(databasesValuesOptions?.data));
            setPageNumber(pageNumber + 1)
        }
    }
    let options = JSON.parse(JSON.stringify(databasesValuesOptions?.data))
    if (databasesValuesOptions && databasesValuesOptions.data && databasesValuesOptions?.data?.length > 0 && oldDatabaseOptions?.length > 0) {
        options = [...oldDatabaseOptions, ...options];
    }
    useEffect(() => {
        dispatch(getMasterDataByType({ masterDataType: 'databases', pageNumber: pageNumber, pageSize: '10', searchString: searchText }));
    }, [dispatch, pageNumber, searchText]);

    return (
        <div className="p-4">
            <div className="bg-white border-2 p-2 text-[#696969]">
                {databasesValue?.length > 0 ?
                    <>
                        <div>Databases</div>
                        <div className="flex gap-0.5 flex-wrap">
                            {databasesValue.map((selectedDatabase, index) =>
                                <Tag key={index} type='filled' onClose={() => setDatabasesValue(databasesValue.filter((database) => database !== selectedDatabase))}>{options?.filter((database: { id: string, name: string }) => database.id === selectedDatabase)?.[0]?.name}</Tag>
                            )}
                        </div>
                    </>
                    :
                    <div>Select the database</div>
                }
            </div>
            <Search className="px-0 w-full"
                onSearch={handleSearch} placeholder="Search" searchValue={searchText} onChange={handleSearch} />
            <div className="border-2 p-4 h-72 overflow-auto" onScroll={handleScroll}>
                <div className="py-4 font-bold">Name</div>
                <CheckboxGroup value={databasesValue} name="database" onChange={onChange}>
                    {options?.map((database: { id: string, name: string }) => <Checkbox className="p-2" value={database?.id}>{database?.name}</Checkbox>)}
                </CheckboxGroup>
            </div>
            <div className="flex justify-end gap-2 mt-4">
                <Button kind='secondary' onClick={() => setActiveIndex(0)}>Back</Button>
                <Button kind='primary' icon="icon-arrow-right-small-outline" disabled={databasesValue?.length <= 0} onClick={() => setActiveIndex(2)}>Select table</Button>
            </div>

        </div>
    )
}

export default DatabaseSelectionSection;