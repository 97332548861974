import { Badge, Button, Column, Pagination, Select, Table } from '@appkit4/react-components';
import { itemsPerPageOptions, requestTypeMapping } from '../../../services/constants';

const AssetRequestsSectionManager: React.FC<{
    currentPending: number,
    selectedValuePending: string,
    onPageChangePending: any,
    setSelectedValuePending: any,
    currentNew: number,
    selectedValueNew: string,
    onPageChangeNew: any,
    setSelectedValueNew: any,
    reviewAsset: any,
    newRequestsList: any,
    pendingRequestsList: any,
    clearAll: any
}> = ({
    currentPending,
    selectedValuePending,
    onPageChangePending,
    setSelectedValuePending,
    currentNew,
    selectedValueNew,
    onPageChangeNew,
    setSelectedValueNew,
    reviewAsset,
    newRequestsList,
    pendingRequestsList,
    clearAll
}) => {
        return (
            <>
                <div className="bg-white p-8 mb-8">
                    <div className="flex justify-between my-4">
                        <div><Badge value={newRequestsList?.totalItems || 0} type={'primary'} /><span className="font-medium text-xl pl-1">New requests</span></div>
                        <Button className="underline p-0 ml-2" kind="text" disabled={newRequestsList?.data?.length <= 0} onClick={clearAll}>Clear all</Button>
                    </div>
                    {newRequestsList?.data?.length > 0 ?
                        <Table className="shadow-none" originalData={newRequestsList?.data ? JSON.parse(JSON.stringify(newRequestsList?.data)) : []} hasTitle>
                            <Column field='requestDate' sortKey='requestDate' renderCell={(row) => <span>{Math.floor((new Date().getTime() - new Date(row.requestDate).getTime()) / (1000 * 60 * 60 * 24))} days ago</span>}>Date</Column>
                            <Column field='assetName' sortKey='assetName'>Data Asset</Column>
                            <Column field='requestType' sortKey='requestType' renderCell={(row) => <span>{requestTypeMapping.filter(type => type.value === row.requestType)[0]?.label}</span>}>Request</Column>
                            <Column field='requestBy' sortKey='requestBy'>Owner</Column>
                            <Column field='assetBusinessId' sortKey='assetBusinessId' renderCell={(row) => <Button kind="secondary" onClick={() => reviewAsset(row)}>Review request</Button>}></Column>
                        </Table>
                        :
                        <div>There is no data to display</div>
                    }
                    <div className="flex justify-between">
                        <div className="flex mt-4 gap-2 items-center">
                            <span>Show</span>
                            <Select className="!w-20"
                                placeholder="drop"
                                hideTitleOnInput={true}
                                data={itemsPerPageOptions}
                                value={selectedValueNew}
                                onSelect={(e) => setSelectedValueNew(e.toString())}
                            >
                            </Select>
                            <span>items per page</span>
                        </div>
                        <Pagination current={currentNew} total={newRequestsList?.totalPages || 1} onPageChange={onPageChangeNew}></Pagination>
                    </div>
                </div>
                <div className="bg-white p-8 mb-8">
                    <div className="flex justify-between my-4">
                        <div className="font-medium text-xl">Pending requests</div>
                    </div>
                    {pendingRequestsList?.data?.length > 0 ?
                        <Table className="shadow-none" originalData={pendingRequestsList?.data ? JSON.parse(JSON.stringify(pendingRequestsList?.data)) : []} hasTitle>
                            <Column field='requestDate' sortKey='requestDate' renderCell={(row) => <span>{Math.floor((new Date().getTime() - new Date(row.requestDate).getTime()) / (1000 * 60 * 60 * 24))} days ago</span>}>Date</Column>
                            <Column field='assetName' sortKey='assetName'>Data Asset</Column>
                            <Column field='requestType' sortKey='requestType' renderCell={(row) => <span>{requestTypeMapping.filter(type => type.value === row.requestType)[0]?.label}</span>}>Request</Column>
                            <Column field='requestBy' sortKey='requestBy'>Owner</Column>
                            <Column field='assetBusinessId' sortKey='assetBusinessId' renderCell={(row) => <Button kind="secondary" onClick={() => reviewAsset(row)}>Review request</Button>}></Column>
                        </Table>
                        :
                        <div>There is no data to display</div>
                    }
                    <div className="flex justify-between">
                        <div className="flex mt-4 gap-2 items-center">
                            <span>Show</span>
                            <Select className="!w-20"
                                placeholder="drop"
                                hideTitleOnInput={true}
                                data={itemsPerPageOptions}
                                value={selectedValuePending}
                                onSelect={(e) => setSelectedValuePending(e.toString())}
                            >
                            </Select>
                            <span>items per page</span>
                        </div>
                        <Pagination current={currentPending} total={pendingRequestsList?.totalPages || 1} onPageChange={onPageChangePending}></Pagination>
                    </div>
                </div>
            </>
        )
    }

export default AssetRequestsSectionManager;