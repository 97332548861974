export const typeData = [
    { value: 'type1', label: 'type1' },
    { value: 'type2', label: 'type2' },
    { value: 'type3', label: 'type3' },
    { value: 'type4', label: 'type4' },
    { value: 'dmw-type', label: 'dmw-type'},
    { value: 'dataMigration', label: 'Data migration'},
    { value: 'dwh_migration', label: 'DWH Migration'}
];

export const multiSelectData = [
    { value: 'email1@pwc.com', label: 'email1@pwc.com' },
    { value: 'email2@pwc.com', label: 'email2@pwc.com' },
    { value: 'email3@others.com', label: 'email3@others.com' },
    { value: 'email4@others.com', label: 'email4@others.com' },
    { value: 'email5@pwc.com', label: 'email4@pwc.com' }
];

export const projectDetailsData = [
    {
        "id": "1",
        "name": "New Project 1",
        "type": "Project Type",
        "description": "Project Description",
        "ownerId": "e278f9fc-1835-4145-bcc8-06ff588660ce",
        "adminList": [
            "e278f9fc-1835-4145-bcc8-06ff588660ce"
        ],
        "managerList": [
            "e278f9fc-1835-4145-bcc8-06ff588660ce"
        ],
        "startDate": "2024-08-20T09:49:21.309Z",
        "endDate": "2024-08-20T09:49:21.309Z",
        "projectStatus": "ongoing"
    },
    {
        "id": "2",
        "name": "New Project 2",
        "type": "Project Type new",
        "description": "Project Description new",
        "ownerId": "e278f9fc-1835-4145-bcc8-06ff588660ce",
        "adminList": [
            "e278f9fc-1835-4145-bcc8-06ff588660ce"
        ],
        "managerList": [
            "e278f9fc-1835-4145-bcc8-06ff588660ce"
        ],
        "startDate": "2024-08-20T09:49:21.309Z",
        "endDate": "2024-08-20T09:49:21.309Z",
        "projectStatus": "ongoing"
    },
    {
        "id": "3",
        "name": "New Project 3",
        "type": "Project Type new12",
        "description": "Project Description new12",
        "ownerId": "e278f9fc-1835-4145-bcc8-06ff588660ce",
        "adminList": [
            "e278f9fc-1835-4145-bcc8-06ff588660ce"
        ],
        "managerList": [
            "e278f9fc-1835-4145-bcc8-06ff588660ce"
        ],
        "startDate": "2024-08-20T09:49:21.309Z",
        "endDate": "2024-08-20T09:49:21.309Z",
        "projectStatus": "Not started"
    }
];

export const archiveProjectData = [
    {
        "name": "New Project 1",
        "type": "Project Type",
        "description": "Project Description",
        "ownerId": "e278f9fc-1835-4145-bcc8-06ff588660ce",
        "adminList": [
            "e278f9fc-1835-4145-bcc8-06ff588660ce"
        ],
        "managerList": [
            "e278f9fc-1835-4145-bcc8-06ff588660ce"
        ],
        "startDate": "2024-08-20T09:49:21.309Z",
        "endDate": "2024-08-20T09:49:21.309Z",
        "status": "Archieved"
    }
];

export const dataAssetstobeMigrated = [
    {
        name: "AI Model",
        icon: "bot",
        noOfAssets: "1256"
    },
    {
        name: "Applications",
        icon: "box",
        noOfAssets: "25"
    },
    {
        name: "Dashboards",
        icon: "dashboard",
        noOfAssets: "324"
    },
    {
        name: "Operational Datafeed",
        icon: "group",
        noOfAssets: "2335"
    },
    {
        name: "Reports",
        icon: "document-text",
        noOfAssets: "8999"
    },
    {
        name: "SAS Scripts",
        icon: "query",
        noOfAssets: "403"
    },
]

export const listOfUsersMock = [
    {
        "users": [
            {
                "id": "9e9-4776-9398-4bd7f21dcc1d",
                "email": "vishal@gmail.com",
                "role": "admin",
                "active": true
            }
        ],
        "role": "admin",
        "count": 1
    },
    {
        "users": [
            {
                "id": "097-4dcf-94b8-783d8d37dc9d",
                "email": "soumadip@gmail.com",
                "role": "migration-manager",
                "active": true
            }
        ],
        "role": "migration-manager",
        "count": 1
    }
]

export const mockConfigData = {
  "projectId": "string",  
  "config": {    
    "gcsBucket": "bucketId",    
    "folder": "new_folder",    
    "serviceAccount": "accolunt1"  
  }
}

export const mockDataAssetsTableData = [
    {
        id: "1",
        assetType: "Report",
        assetName: "asset name1",
        assetOwner: "abc@xyz.com",
        surveyStatus: "ongoing",
    },
    {
        id: "2",
        assetType: "Script",
        assetName: "asset name2",
        assetOwner: "abcd@xyz.com",
        surveyStatus: "notStarted",
    },
    {
        id: "3",
        assetType: "Dashboard",
        assetName: "asset name3",
        assetOwner: "abcdef@xyz.com",
        surveyStatus: "inReview",
    },
    {
        id: "4",
        assetType: "Application",
        assetName: "asset name4",
        assetOwner: "bdc@xyz.com",
        surveyStatus: "ongoing",
    },
    {
        id: "5",
        assetType: "Table",
        assetName: "asset name5",
        assetOwner: "absddfsc@xyz.com",
        surveyStatus: "notStarted",
    },
    {
        id: "6",
        assetType: "Report",
        assetName: "asset name6",
        assetOwner: "abcasdwed@xyz.com",
        surveyStatus: "complete",
    },
    {
        id: "7",
        assetType: "Table",
        assetName: "asset name7",
        assetOwner: "ghy@xyz.com",
        surveyStatus: "notStarted",
    },
    {
        id: "8",
        assetType: "Dashboard",
        assetName: "asset name8",
        assetOwner: "bgy@xyz.com",
        surveyStatus: "ongoing",
    }
]

export const assetDetail = {
    "Asset type": "script",
    "Asset description": "This is an asset description. This can be anything",
    "Created by": "John Doe",
    "Created date": "25/03/2021",
    "Updated by": "Max Masterman",
    "Updated date": "12/04/2022",
    "criticality": "Medium",
    "Environment": "-",
    "Migration status": "-",
    "Use case": "-"
}

export const scopeDetails = {
    "Last Update": "12/01/2024",
    "AI Models": "7",
    "Applications": "4342",
    "Dashboard": "23",
    "Operational Datafeed": "342",
    "Report": "178",
    "SAS Script": "1345"
}

export const newRequests = [
    {
        userName: "max.mjustermann",
        requestType: "Ownership Of Asset"
    },
    {
        userName: "max.mjustermann",
        requestType: "Removal Of Asset"
    },
    {
        userName: "max.mjustermann",
        requestType: "Removal Of Asset"
    },
    {
        userName: "max.mjustermann",
        requestType: "Removal Of Asset"
    }
];

export const useCasesMockData = [
    {
        name: "name of use case 1",
        owner: "soumadip.pal@pwc.com",
        type: "operational",
        size: "S",
        complexity: "S",
        assets: "6"
    },
    {
        name: "name of use case 2",
        owner: "pritam.m.maity@pwc.com",
        type: "reports",
        size: "L",
        complexity: "S",
        assets: "8"
    },
    {
        name: "name of use case 3",
        owner: "vishal.va.anand@pwc.com",
        type: "applications",
        size: "M",
        complexity: "S",
        assets: "10"
    },
    {
        name: "name of use case 4",
        owner: "abc@pwc.com",
        type: "noType",
        size: "S",
        complexity: "M",
        assets: "12"
    },
    {
        name: "name of use case 5",
        owner: "xyz@pwc.com",
        type: "operational",
        size: "S",
        complexity: "L",
        assets: "14"
    }
];

export const wavesMockData = [
    {
        name: "name of use case 1",
        owner: "soumadip.pal@pwc.com",
        status: "Planned",
        startDate: "xx.xx.xxxx",
        endDate: "xx.xx.xxxx",
    },
    {
        name: "name of use case 2",
        owner: "pritam.m.maity@pwc.com",
        status: "Completed",
        startDate: "xx.xx.xxxx",
        endDate: "xx.xx.xxxx",
    },
    {
        name: "name of use case 3",
        owner: "vishal.va.anand@pwc.com",
        status: "Completed",
        startDate: "xx.xx.xxxx",
        endDate: "xx.xx.xxxx",
    },
    {
        name: "name of use case 4",
        owner: "abc@pwc.com",
        status: "Ongoing",
        startDate: "xx.xx.xxxx",
        endDate: "xx.xx.xxxx",
    },
    {
        name: "name of use case 5",
        owner: "xyz@pwc.com",
        status: "Ongoing",
        startDate: "xx.xx.xxxx",
        endDate: "xx.xx.xxxx",
    }
];

export const surveyMockData = [
    {
        useCase: "HR_244D_",
        user: "soumadip.pal@pwc.com",
        status: "Ready for review",
        wave: "HR Data",
        deadLine: "xx.xx.xxxx",
    },
    {
        useCase: "AI Details",
        user: "soumadip.pal@pwc.com",
        status: "Ready for review",
        wave: "Sales Data",
        deadLine: "xx.xx.xxxx",
    },
    {
        useCase: "FINANCE_TABLE",
        user: "soumadip.pal@pwc.com",
        status: "Ready for review",
        wave: "Financial Data",
        deadLine: "xx.xx.xxxx",
    }
];

export const dataAssetsToBeMigratedMockData = [
    {
        status: "Completed",
        numberOfSurveys: "69",
        percentage: "20%"
    },
    {
        status: "Ongoing",
        numberOfSurveys: "120",
        percentage: "35%"
    },
    {
        status: "Not Started",
        numberOfSurveys: "155",
        percentage: "45%"
    },
]

export const requestsHistoryTableMockData = [
    {
        requestDate: "2024-11-18T03:26:33.432Z",
        assetName: "Asset Name ######",
        requestType: "Ownership of Asset",
        requestBy: "soumadip.pal@pwc.com",
        assetBusinessId: "1"
    },
    {
        requestDate: "2024-11-16T01:25:33.432Z",
        assetName: "Asset Name ######",
        requestType: "Removal of ownership",
        requestBy: "abc@pwc.com",
        assetBusinessId: "2"
    },
    {
        requestDate: "2024-11-15T03:26:33.432Z",
        assetName: "Asset Name ######",
        requestType: "New use case",
        requestBy: "xyz@pwc.com",
        assetBusinessId: "3"
    },
    {
        requestDate: "2024-10-18T03:26:33.432Z",
        assetName: "Asset Name ######",
        requestType: "Survey",
        requestBy: "rintu@pwc.com",
        assetBusinessId: "4"
    },
    {
        requestDate: "2024-11-14T03:26:33.432Z",
        assetName: "Asset Name ######",
        requestType: "Ownership of Asset",
        requestBy: "pal@pwc.com",
        assetBusinessId: "5"
    },
    {
        requestDate: "2024-11-11T03:26:33.432Z",
        assetName: "Asset Name ######",
        requestType: "Ownership of Asset",
        requestBy: "soumadip.pal@pwc.com",
        assetBusinessId: "6"
    }
];

export const pendingRequestsTableMockData = [
    {
        date: "xx.xx.xxxx",
        actionItem: "Asset Name ######",
        request: "Ownership of Asset",
        owner: "soumadip.pal@pwc.com"
    },
    {
        date: "xx.xx.xxxx",
        actionItem: "Asset Name ######",
        request: "Removal of ownership",
        owner: "abc@pwc.com"
    },
    {
        date: "xx.xx.xxxx",
        actionItem: "Asset Name ######",
        request: "New use case",
        owner: "xyz@pwc.com"
    },
    {
        date: "xx.xx.xxxx",
        actionItem: "Asset Name ######",
        request: "Survey",
        owner: "rintu@pwc.com"
    },
    {
        date: "xx.xx.xxxx",
        actionItem: "Asset Name ######",
        request: "Ownership of Asset",
        owner: "pal@pwc.com"
    },
    {
        date: "xx.xx.xxxx",
        actionItem: "Asset Name ######",
        request: "Removal of ownership",
        owner: "soumadip.pal@pwc.com"
    },
    {
        date: "xx.xx.xxxx",
        actionItem: "Asset Name ######",
        request: "Survey",
        owner: "pqr@pwc.com"
    },
    {
        date: "xx.xx.xxxx",
        actionItem: "Asset Name ######",
        request: "New use case",
        owner: "pwc@pwc.com"
    },
    {
        date: "xx.xx.xxxx",
        actionItem: "Asset Name ######",
        request: "Ownership of Asset",
        owner: "lls@pwc.com"
    },
];

export const mockDatabaseValues = [
    {
        label: "Database 1",
        value: "database1"
    },
    {
        label: "Database 2",
        value: "database2"
    },
    {
        label: "Database 3",
        value: "database3"
    },
    {
        label: "Database 4",
        value: "database4"
    },
    {
        label: "Database 5",
        value: "database5"
    },
    {
        label: "Database 6",
        value: "database6"
    },
    {
        label: "Database 7",
        value: "database7"
    },
    {
        label: "Database 8",
        value: "database8"
    },
    {
        label: "Database 9",
        value: "database9"
    },
    {
        label: "Database 10",
        value: "database10"
    }
];

export const mockTableValues = [
    {
        label: "Table 1",
        value: "table1"
    },
    {
        label: "Table 2",
        value: "table2"
    },
    {
        label: "Table 3",
        value: "table3"
    },
    {
        label: "Table 4",
        value: "table4"
    },
    {
        label: "Table 5",
        value: "table5"
    },
    {
        label: "Table 6",
        value: "table6"
    },
    {
        label: "Table 7",
        value: "table7"
    },
    {
        label: "Table 8",
        value: "table8"
    },
    {
        label: "Table 9",
        value: "table9"
    },
    {
        label: "Table 10",
        value: "table10"
    }
];

export const mockTechnologyValues = [
    {
        label: "Technology 1",
        value: "technology1"
    },
    {
        label: "Technology 2",
        value: "technology2"
    },
    {
        label: "Technology 3",
        value: "technology3"
    },
    {
        label: "Technology 4",
        value: "technology4"
    },
    {
        label: "Technology 5",
        value: "technology5"
    },
    {
        label: "Technology 6",
        value: "technology6"
    },
    {
        label: "Technology 7",
        value: "technology7"
    },
    {
        label: "Technology 8",
        value: "technology8"
    },
    {
        label: "Technology 9",
        value: "technology9"
    },
    {
        label: "Technology 10",
        value: "technology10"
    }
]