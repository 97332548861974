import { Badge, Button, Column, Input, Pagination, Select, Table, TextArea } from "@appkit4/react-components";
import { itemsPerPageOptions, requestTypeMapping } from "../../../services/constants";
import { RequestDTO } from "../../../api";
import { useSelector } from "react-redux";
import { selectAssetRequestsHistoryList } from "../../../store/project/projectSelectors";

const RequestHistoryListManager: React.FC<{
    currentHistoryPage: number,
    selectedValueHistoryPage: string,
    onPageChangeRequests: any,
    setSelectedValueHistoryPage: any,
    isRequestDetailsShow: boolean
    setIsRequestDetailsShow: any,
    selectedRequest: any,
    setSelectedRequest: Function
}> = ({
    currentHistoryPage,
    selectedValueHistoryPage,
    onPageChangeRequests,
    setSelectedValueHistoryPage,
    isRequestDetailsShow,
    setIsRequestDetailsShow,
    selectedRequest,
    setSelectedRequest
}) => {
    const seeRequestDetails = (row: RequestDTO) => {
        setIsRequestDetailsShow(true);
        setSelectedRequest(row);
    }
    const requestHistoryListData = JSON.parse(JSON.stringify(useSelector(selectAssetRequestsHistoryList)));
    return (
        isRequestDetailsShow ?
        <>
            <div className="flex justify-between">
                <div className="font-sans font-medium text-xl my-4">Request</div>
                <Button kind="primary" icon="icon-update-outline" onClick={() => setIsRequestDetailsShow(false)}>Request History</Button>
            </div>
            <div className="bg-white p-8 mb-8">
                <div className="flex">
                    <div className="font-sans font-medium text-xl my-4">{selectedRequest?.requestType === "removeOwnership" ? "Asset removal" : "Ownership of an asset"}</div>
                    <Badge className="m-5" value={selectedRequest?.actionType === "deny" ? "Declined" : "Approved"} type={selectedRequest?.actionType === "deny" ? "danger" : "success"}/>
                </div>
                <div className="text-[#696969] mb-4">{`The following user has requested for ${selectedRequest?.requestType === "removeOwnership" ? "removal" : "ownership"}  of the asset. Please review provided reason from the user and deny or approve the request.`}</div> 
                <div className="my-4">
                    <Input required={false} type="text" title="Asset name" readonly value={selectedRequest?.assetName} />
                </div>
                <div className="my-4">
                    <Input required={false} type="text" title="Requested By" readonly value={selectedRequest?.requestBy} />
                </div>
                <div className="my-4">
                    <TextArea required={false} title="Description" readonly value={selectedRequest?.requestDescription} />
                </div>
            </div>
            {selectedRequest?.actionType === "deny" &&
                <div className="bg-white p-8 mb-8">
                    <div className="font-sans font-medium text-xl my-4">Denial Comments</div>
                    <div className="my-4">
                        <Input required={false} type="text" title="Denied By" readonly value={selectedRequest?.actionedBy} />
                    </div>
                    <div className="my-4">
                        <TextArea required={false} title="Description" readonly value={selectedRequest?.actionDescription} />
                    </div>
                </div>
            }
        </>
        :
        <div className="bg-white p-8 mb-8">
            <div className="flex justify-between my-4">
                <div className="font-medium text-xl pl-1">Request management</div>
            </div>
            {requestHistoryListData?.data?.length > 0 ?
                <Table className="shadow-none" originalData={requestHistoryListData?.data ? JSON.parse(JSON.stringify(requestHistoryListData?.data)) : []} hasTitle>
                    <Column field='requestDate' sortKey='requestDate' renderCell={(row) => <span>{Math.floor((new Date().getTime() - new Date(row.requestDate).getTime()) / (1000 * 60 * 60 * 24))} days ago</span>}>Date</Column>
                    <Column field='assetName' sortKey='assetName'>Data Asset</Column>
                    <Column field='requestType' sortKey='requestType' renderCell={(row) => <span>{requestTypeMapping.filter(type => type.value === row.requestType)[0]?.label}</span>}>Request</Column>
                    <Column field='requestBy' sortKey='requestBy'>Owner</Column>
                    <Column field='assetBusinessId' sortKey='assetBusinessId' renderCell={(row) => (row.requestType === "removeOwnership" || row.requestType === "ownershipOfAsset") && <Button kind="secondary" onClick={() =>seeRequestDetails(row)}>See request</Button>}></Column>
                </Table>
                :
                <div>There is no data to display</div>
            }
            <div className="flex justify-between">
                <div className="flex mt-4 gap-2 items-center">
                    <span>Show</span>
                    <Select className="!w-20"
                        placeholder="drop"
                        hideTitleOnInput={true}
                        data={itemsPerPageOptions}
                        value={selectedValueHistoryPage}
                        onSelect={(e) => setSelectedValueHistoryPage(e.toString())}
                    >
                    </Select>
                    <span>items per page</span>
                </div>
                <Pagination current={currentHistoryPage} total={requestHistoryListData?.totalPages || 1} onPageChange={onPageChangeRequests}></Pagination>
            </div>
        </div>
    )
}

export default RequestHistoryListManager;