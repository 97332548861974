import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssetDTO, AssetMasterDataDTO, ProjectScopeDTO, GetAllAssetsResponse, ProjectConfigDTO, ProjectDTO, RequestListDTO, UserByRole, RequestDTO, ConnectivityResponseDTO } from '../../api/types.gen';

interface ProjectState {
  loading: boolean;
  error: string | null;
  userEmailsTableData: Array<any>;
  projectInitialDetails: object;
  usersByRoleList: UserByRole[];
  allProjectsList: ProjectDTO[];
  userEmailsForProject: Array<any>;
  selectedProjectData: ProjectDTO;
  selectedProjectConfigData: ProjectConfigDTO;
  assetDataForProject: GetAllAssetsResponse;
  selectedAsset: AssetDTO;
  isEditModeAsset: boolean;
  allNewReqDataAsset: RequestListDTO;
  allPendingReqDataAsset: RequestListDTO;
  allRequestHistoryData: RequestListDTO;
  scopeDataOverview: ProjectScopeDTO;
  databasesValuesOptions: AssetMasterDataDTO;
  tablesValuesOptions: AssetMasterDataDTO;
  technologiesValuesOptions: AssetMasterDataDTO;
  oldDatabaseOptions: Array<any>;
  oldTablesOptions: Array<any>;
  oldTechnologiesOptions: Array<any>;
  initialConfigData: object;
  reqDetailForAsset: RequestDTO;
  connectivityResponse: {connectivity: boolean, message: string};
}

const initialState: ProjectState = {
  loading: false,
  error: null,
  allProjectsList: [],
  userEmailsTableData: [],
  usersByRoleList: [],
  projectInitialDetails: {
    name: "",
    type: "",
    startDate: null,
    endDate: null,
    description: "",
    adminEmails: [],
    managerEmails: []
  },
  selectedProjectData: {
    name: "",
    projectTechnicalId: "",
    projectBusinessId: "",
    projectStatus: "ongoing",
    type: "",
    startDate: "",
    endDate: "",
    description: "",
    adminList: [],
    managerList: [],
    projectConfigDetails: {
      gcsBucket: "",
      bigQueryId: ""
    }
  },
  userEmailsForProject: [],
  selectedProjectConfigData: {
    projectTechnicalId: "",
    projectBusinessId: "",
    config: {
    }
  },
  assetDataForProject: {
  },
  selectedAsset: {
    assetTechnicalId: '',
    assetBusinessId: '',
    assetName: '',
    assetType: 'aiModel',
    projectBusinessId: '',
    projectTechnicalId: ''
  },
  isEditModeAsset: false,
  allNewReqDataAsset: {},
  allPendingReqDataAsset: {},
  allRequestHistoryData: {},
  scopeDataOverview: {
    aiModel: "",
    application: "",
    sasScript: "",
    dashboard: "",
    report: "",
    operationalDatafeed: "",
    totalAssets: "",
    lastUpdated: ""
  },
  databasesValuesOptions: {
    data: [],
    pageNumber: 1,
    pageSize: 10,
    totalItems: 0
  },
  tablesValuesOptions: {
    data: [],
    pageNumber: 1,
    pageSize: 10,
    totalItems: 0
  },
  technologiesValuesOptions: {
    data: [],
    pageNumber: 1,
    pageSize: 10,
    totalItems: 0
  },
  oldDatabaseOptions: [],
  oldTablesOptions: [],
  oldTechnologiesOptions: [],
  initialConfigData: {},
  reqDetailForAsset: {
    assetTechnicalId: "",
    assetBusinessId: "",
    assetName: "",
    requestTechnicalId: "",
    requestBusinessId: "",
    requestType: "approvedNewAsset",
    requestBy: "",
    requestStatus: "approved"
  },
  connectivityResponse: {connectivity: false, message: ""}
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setUserEmailsTableData: (state, action: PayloadAction<Array<Object>>) => {
      state.userEmailsTableData = action.payload
    },
    setProjectInitialDetails: (state, action: PayloadAction<object>) => {
      state.projectInitialDetails = action.payload
    },
    setUsersByRole: (state, action: PayloadAction<UserByRole[]>) => {
      state.usersByRoleList = action.payload
    },
    setAllProjectsList: (state, action: PayloadAction<ProjectDTO[]>) => {
      state.allProjectsList = action.payload
    },
    setUserEmailsForProject: (state, action: PayloadAction<Array<Object>>) => {
      state.userEmailsForProject = action.payload
    },
    setSelectedProjectData: (state, action: PayloadAction<ProjectDTO>) => {
      state.selectedProjectData = action.payload
    },
    setProjectConfigData: (state, action: PayloadAction<ProjectConfigDTO>) => {
      state.selectedProjectConfigData = action.payload
    },
    setAssetDataForProject: (state, action: PayloadAction<GetAllAssetsResponse>) => {
      state.assetDataForProject = action.payload
    },
    setSelectedAsset: (state, action: PayloadAction<AssetDTO>) => {
      state.selectedAsset = action.payload
    },
    setIsEditModeAsset: (state, action: PayloadAction<boolean>) => {
      state.isEditModeAsset = action.payload
    },
    setAllNewReqDataAsset: (state, action: PayloadAction<RequestListDTO>) => {
      state.allNewReqDataAsset = action.payload
    },
    setAllPendingReqDataAsset: (state, action: PayloadAction<RequestListDTO>) => {
      state.allPendingReqDataAsset = action.payload
    },
    setRequestHistoryData: (state, action: PayloadAction<RequestListDTO>) => {
      state.allRequestHistoryData = action.payload
    },
    setScopeDataOverview: (state, action: PayloadAction<ProjectScopeDTO>) => {
      state.scopeDataOverview = action.payload
    },
    setDatabasesOptions: (state, action: PayloadAction<AssetMasterDataDTO>) => {
      state.databasesValuesOptions = action.payload
    },
    setTablesOptions: (state, action: PayloadAction<AssetMasterDataDTO>) => {
      state.tablesValuesOptions = action.payload
    },
    setTechnologiesOptions: (state, action: PayloadAction<AssetMasterDataDTO>) => {
      state.technologiesValuesOptions = action.payload
    },
    setOldDatabasesOptions: (state, action: PayloadAction<Array<any>>) => {
      state.oldDatabaseOptions = action.payload
    },
    setOldTablesOptions: (state, action: PayloadAction<Array<any>>) => {
      state.oldTablesOptions = action.payload
    },
    setOldTechnologiesOptions: (state, action: PayloadAction<Array<any>>) => {
      state.oldTechnologiesOptions = action.payload
    },
    setInitialProjectConfigData: (state, action: PayloadAction<object>) => {
      state.initialConfigData = action.payload
    },
    setReqDetailForAsset: (state, action: PayloadAction<RequestDTO>) => {
      state.reqDetailForAsset = action.payload
    },
    setConnectivityResponse: (state, action: PayloadAction<{connectivity: boolean, message: string}>) => {
      state.connectivityResponse = action.payload
    }
  },
});

export const { setLoading, setError, setUserEmailsTableData, setProjectInitialDetails, setUsersByRole, setAllProjectsList,
  setUserEmailsForProject, setSelectedProjectData, setProjectConfigData, setAssetDataForProject, setSelectedAsset, setIsEditModeAsset, setAllNewReqDataAsset, 
  setAllPendingReqDataAsset, setRequestHistoryData, setScopeDataOverview, setDatabasesOptions, setTablesOptions, setTechnologiesOptions, setOldDatabasesOptions, setOldTablesOptions, setOldTechnologiesOptions,
  setInitialProjectConfigData, setReqDetailForAsset, setConnectivityResponse } = projectSlice.actions;
export default projectSlice.reducer;